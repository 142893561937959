// import './src/dependencies';

import '@hotwired/turbo-rails';

import './src/passwords';
import './src/direct_upload';

import { Lightbox } from './src/lightbox';
import { Slider } from './src/slider';

import { Tooltip, Dropdown, Modal } from 'bootstrap';

const initTooltips = () => {
  const elements = document.querySelectorAll('.with-tooltip');
  for (const element of elements) {
    new Tooltip(element, { delay: { show: 500, hide: 100 } });
  }
};

const initDropdowns = () => {
  const elements = document.querySelectorAll('.dropdown-toggle');
  for (const element of elements) {
    new Dropdown(element);
  }
};

const initBuyTrainingModal = () => {
  const element = document.querySelector<HTMLDivElement>('.buy-training-modal');
  if (!element) return;
  const modal = new Modal(element);
  modal.show();
};

const initSliders = () => {
  Slider.init();
};

const initLightbox = () => {
  Lightbox.init();
};

const init = () => {
  initTooltips();
  initDropdowns();
  initBuyTrainingModal();
  initSliders();
  initLightbox();
};

document.addEventListener('turbo:load', init);
