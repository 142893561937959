type TagNameType = keyof HTMLElementTagNameMap;
type EventNameType = keyof HTMLElementEventMap;
type EventHandler<K extends EventNameType> = (
  event: HTMLElementEventMap[K]
) => any;
type EventHandlers = { [K in EventNameType]?: EventHandler<K> };
type ElementOrTagNameType = TagNameType | HTMLElement;
type PropertyParameters =
  | [string, string]
  | [string]
  | [{ [k in string]: string }];
type EventHandlerParameters =
  | [EventNameType, EventHandler<EventNameType>]
  | [EventHandlers];

export class ElementBuilder {
  element: HTMLElement;

  constructor(element: ElementOrTagNameType) {
    this.element =
      element instanceof HTMLElement
        ? element
        : document.createElement(element);
  }

  append(...nodes: this[]) {
    this.element.append(...nodes.map((n) => n.element));
    return this;
  }

  attr(key: string): string;
  attr(key: string, value: string): this;
  attr(values: { [k in string]: string }): this;
  attr(...args: PropertyParameters) {
    if (args.length === 2) {
      const key = args[0];
      const value = args[1];
      this.element.setAttribute(key, value);
    } else if (typeof args[0] === 'string') {
      const key = args[0];
      return this.element.getAttribute(key);
    } else {
      const values = args[0];
      for (const key in values) {
        const value = values[key];
        this.attr(key, value);
      }
    }
    return this;
  }

  data(key: string): string;
  data(key: string, value: string): this;
  data(values: { [k in string]: string }): this;
  data(...args: PropertyParameters) {
    if (args.length === 2) {
      const key = args[0];
      const value = args[1];
      this.element.dataset[key] = value;
    } else if (typeof args[0] === 'string') {
      const key = args[0];
      return this.element.dataset[key] || '';
    } else {
      const values = args[0];
      for (const key in values) {
        this.data(key, values[key]);
      }
    }
    return this;
  }

  css(key: string): string;
  css(key: string, value: string): this;
  css(values: { [k in string]: string }): this;
  css(...args: PropertyParameters) {
    if (args.length === 2) {
      const [key, value] = args;
      this.element.style.setProperty(key, value);
    } else if (typeof args[0] === 'string') {
      const [key] = args;
      return this.element.style.getPropertyValue(key);
    } else {
      const [values] = args;
      for (const key in values) {
        this.css(key, values[key]);
      }
    }

    return this;
  }

  on(event: EventNameType, handler: EventHandler<EventNameType>): this;
  on(handlers: EventHandlers): this;
  on(...args: EventHandlerParameters) {
    if (args.length == 2) {
      const [event, handler] = args;
      this.element.addEventListener(event, handler);
    } else {
      const [handlers] = args;
      for (const key in handlers) {
        this.element.addEventListener(
          key,
          handlers[key as keyof EventHandlers]
        );
      }
    }
    return this;
  }

  find(selector: string) {
    return new ElementBuilder(
      this.element.querySelector(selector) as HTMLElement
    );
  }

  findAll(selector: string) {
    return new ElementList(selector, this.element);
  }

  get classList() {
    return this.element.classList;
  }

  html(): string;
  html(html: string): this;
  html(html?: string): this | string {
    if (html) {
      this.element.innerHTML = html;
      return this;
    } else {
      return this.element.innerHTML;
    }
  }

  text(): string;
  text(text: string): this;
  text(text?: string): this | string {
    if (text) {
      this.element.textContent = text;
      return this;
    } else {
      return this.element.textContent || '';
    }
  }

  remove() {
    this.element.remove();
  }
}

export class ElementList {
  context: HTMLElement;
  selector: string;

  constructor(selector: string, context = document.body) {
    this.selector = selector;
    this.context = context;
  }

  get elements() {
    return this.context.querySelectorAll(this.selector);
  }

  get count() {
    return this.elements.length;
  }

  item(i: number) {
    return new ElementBuilder(this.elements.item(i) as HTMLElement);
  }

  index(element: ElementBuilder) {
    for (let [index, el] of this.elements.entries()) {
      if (el === element.element) {
        return index;
      }
    }
  }

  // on(...args: EventHandlerParameters): this {
  //   for (const element of this) {
  //     element.on(...args);
  //   }
  //   return this;
  // }

  // attr(...args: PropertyParameters): this {
  //   for (const element of this) {
  //     element.attr(...args);
  //   }
  //   return this;
  // }

  // css(...args: PropertyParameters): this {
  //   for (const element of this) {
  //     element.css(...args);
  //   }
  //   return this;
  // }

  // data(...args: PropertyParameters): this {
  //   for (const element of this) {
  //     element.data(...args);
  //   }
  //   return this;
  // }

  [Symbol.iterator]() {
    let index = -1;

    return {
      next: () => ({
        value: new ElementBuilder(this.elements.item(++index) as HTMLElement),
        done: !(index in this.elements),
      }),
    };
  }
}

export const e = <K extends ElementOrTagNameType>(tag: K) => {
  return new ElementBuilder(tag);
};

export const ee = (selector: string) => new ElementList(selector);

export const $ = <E extends HTMLElement>(selector: string) =>
  new ElementBuilder(document.querySelector<E>(selector)!);
