import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start();

interface ActiveStorageEvent extends Event {
  detail: {
    id: number;
    file: File;
    progress: number;
    error: string;
  };
  target: HTMLInputElement | HTMLFormElement | null;
  srcElement: HTMLInputElement | HTMLFormElement | null;
}

declare global {
  interface WindowEventMap {
    'direct-upload:initialize': ActiveStorageEvent;
    'direct-upload:start': ActiveStorageEvent;
    'direct-upload:progress': ActiveStorageEvent;
    'direct-upload:error': ActiveStorageEvent;
    'direct-upload:end': ActiveStorageEvent;
  }
}

const getForm = (event: ActiveStorageEvent) => event.target?.closest('form');

const getTemplate = (event: ActiveStorageEvent) =>
  getForm(event)?.querySelector<HTMLTemplateElement>('.file-template');

const getList = (event: ActiveStorageEvent) =>
  getForm(event)?.querySelector<HTMLTableElement>('.file-list');

const getFileElement = (event: ActiveStorageEvent) =>
  document.getElementById(getId(event));

const getProgressBar = (event: ActiveStorageEvent) =>
  getFileElement(event)?.querySelector<HTMLDivElement>('.progress-bar');

const getError = (event: ActiveStorageEvent) =>
  getFileElement(event)?.querySelector<HTMLSpanElement>('.file-item-error');

const getId = (event: ActiveStorageEvent) =>
  `direct-upload-${event.detail?.id}`;

const humanFileSize = (size: number) => {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  const suffix = ['B', 'kB', 'MB', 'GB', 'TB'][i];
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${suffix}`;
};

addEventListener('change', (event) => {
  const input = event.target as HTMLInputElement;
  if (!input.classList.contains('custom-file-input')) return;

  if (!input.files || input.files.length == 0) return;
  const one = input.dataset.one;
  const many = input.dataset.many;
  const text = input.files?.length === 1 ? one : many;
  input.nextElementSibling!.textContent = `${input.files.length} ${text}`;
});

addEventListener('click', (event) => {
  const { target } = event;
  if (!(target instanceof HTMLElement)) return;
  if (!target.matches('.file-item .remove-link')) return;
  event.preventDefault();
  target.closest('.file-item')?.remove();
});

// add events to window for activestorage
addEventListener('direct-upload:initialize', (event: ActiveStorageEvent) => {
  const { detail } = event;
  const { file } = detail;
  const template = getTemplate(event);
  if (!template) return;
  const contents = template.content.cloneNode(true) as HTMLTableRowElement;
  const list = getList(event);
  if (!list) return;
  const name = contents.querySelector('.file-item-name');
  const contentType = contents.querySelector('.file-item-content-type');
  const size = contents.querySelector('.file-item-size');
  if (name) name.textContent = file.name;
  if (contentType) contentType.textContent = file.type;
  if (size) size.textContent = humanFileSize(file.size);
  contents.firstElementChild?.setAttribute('id', getId(event));
  list.appendChild(contents);
});

addEventListener('direct-upload:start', (event: ActiveStorageEvent) => {
  event.preventDefault();
  const element = getFileElement(event);
  if (!element) return;
  element.classList.add('processing');
});

addEventListener('direct-upload:progress', (event: ActiveStorageEvent) => {
  event.preventDefault();
  const { progress } = event.detail;
  const element = getProgressBar(event);
  if (!element) return;
  const percentage = `${progress}%`;
  element.style.width = percentage;
  element.textContent = percentage;
});

addEventListener('direct-upload:error', (event: ActiveStorageEvent) => {
  event.preventDefault();
  const { error } = event.detail;
  const element = getError(event);
  if (!element) return;
  element.textContent = error;
});

addEventListener('direct-upload:end', (event: ActiveStorageEvent) => {
  event.preventDefault();
  const element = getFileElement(event);
  if (!element) return;
  element.classList.remove('processing');
});

// document.addEventListener('turbo:load', init);
