import { ElementBuilder, ElementList, e } from './element';
import { mod } from './helpers';

export class Slider {
  container: ElementBuilder;
  images: ElementList;
  imageContainer: ElementBuilder;
  nextButton: ElementBuilder;
  prevButton: ElementBuilder;
  imageLinks: ElementList;

  index: number = 0;
  fade: boolean;
  autoplay: number;
  aspect_ratio: number;
  hide_arrows: boolean;
  hide_bullets: boolean;

  static init() {
    for (const element of document.querySelectorAll<HTMLDivElement>(
      '.slider'
    )) {
      new Slider(element);
    }
  }

  constructor(element: HTMLDivElement) {
    this.container = e(element);
    this.fade = Boolean(this.container.data('fade')) || false;
    this.autoplay = Number(this.container.data('autoplay')) || 0;
    this.aspect_ratio = Number(this.container.data('aspect-ratio')) || 0.75;
    this.hide_arrows = Boolean(this.container.data('hide-arrows')) || false;
    this.hide_bullets = Boolean(this.container.data('hide-bullets')) || false;

    if (this.fade) this.container.classList.add('fading');
    this.imageContainer = this.container.find('.slider-images');
    this.images = this.imageContainer.findAll('.slider-image');

    this.nextButton = this.container.find('.next-image-link');
    this.prevButton = this.container.find('.prev-image-link');

    this.nextButton.on('click', this.nextImage);
    this.prevButton.on('click', this.prevImage);

    this.imageLinks = this.container.findAll('.slider-image-link');
    for (const link of this.imageLinks) {
      const index = this.imageLinks.index(link);
      if (!index) continue;
      link.on('click', this.onLinkClick(index));
    }

    const paddingTop = `${this.aspect_ratio * 100}%`;
    if (this.fade) this.imageContainer.css('padding-top', paddingTop);
    for (const image of this.images) {
      image.css('padding-top', paddingTop);
    }

    this.showImage(0);

    if (!this.hide_bullets) this.container.classList.add('with-bullets');
    if (this.autoplay) setTimeout(this.nextImage, Number(this.autoplay));
  }

  get activeImage() {
    return this.images.item(this.index);
  }

  get activeLink() {
    return this.imageLinks.item(this.index);
  }

  onLinkClick = (index: number) => () => {
    this.showImage(index);
  };

  nextImage = () => {
    this.showImage(this.index + 1);
  };

  prevImage = () => {
    this.showImage(this.index - 1);
  };

  showImage = (index: number) => {
    this.index = mod(index, this.images.count);
    if (!this.fade)
      this.imageContainer.css('transform', `translateX(-${this.index * 100}%)`);
    for (const link of this.imageLinks) link.classList.remove('active');
    this.activeLink.classList.add('active');
    for (const image of this.images) image.classList.remove('active');
    this.activeImage.classList.add('active');
  };
}

// const init = Slider.initSliders;

// $(init)
// document.addEventListener('turbo:load', init);
