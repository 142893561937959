import { type ElementBuilder, type ElementList, e, ee } from './element';

export class Lightbox {
  images: ElementList;
  index: number = 0;
  visible: boolean = false;
  container: ElementBuilder;
  nextButton: ElementBuilder;
  prevButton: ElementBuilder;
  image: ElementBuilder;
  description: ElementBuilder;

  static init() {
    new Lightbox();
  }

  constructor() {
    this.images = ee('figure.lightbox');
    this.container = e('div').attr('class', 'lightbox-container');
    this.image = e('img').attr('class', 'lightbox-image');
    this.description = e('div').attr('class', 'lightbox-description');
    this.nextButton = e('a').attr('class', 'next').text('→');
    this.prevButton = e('a').attr('class', 'prev').text('←');
    this.container.append(
      this.image,
      this.description,
      this.nextButton,
      this.prevButton
    );
    e(document.body).append(this.container);

    for (const image of this.images) {
      image.on('click', (event: Event) => {
        event.preventDefault();
        const index = this.images.index(image);
        if (index == undefined) return;
        this.showImageAt(index);
        this.show();
      });
    }
    this.container.on('click', this.hide);
    if (this.images.count > 1) {
      this.nextButton.on('click', this.next);
      this.prevButton.on('click', this.prev);
    } else {
      this.nextButton.remove();
      this.prevButton.remove();
    }
  }

  show = () => {
    this.visible = true;
    this.container.classList.add('visible');
  };

  hide = () => {
    this.visible = false;
    this.container.classList.remove('visible');
  };

  next = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    this.showImageAt(this.index === this.images.count - 1 ? 0 : this.index + 1);
  };

  prev = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    this.showImageAt(this.index === 0 ? this.images.count - 1 : this.index - 1);
  };

  showImageAt(index: number) {
    this.index = index;
    const figure = this.images.item(index);
    const image = figure.find('img');
    const caption = figure.find('figcaption');
    this.image.attr('src', image.attr('src')!);
    this.description.text(caption.text());
  }
}
