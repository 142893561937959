let visible = false;

const LOWERCASE = 'abcdefghijklmnopqrstuvwxyz',
  UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  NUMBERS = '0123456789',
  SYMBOLS = '!@#$%^&*()+_-=}{[]|:;"/?.><,`~',
  POOL = LOWERCASE + UPPERCASE + NUMBERS + SYMBOLS,
  MAX = 16,
  MIN = 12;

function getLength() {
  return Math.random() * (MAX - MIN) + MIN;
}

function generate() {
  const length = getLength();
  const buf = new Uint8Array(length);
  crypto.getRandomValues(buf);
  return Array.from(buf)
    .map((n: number) => POOL[n % POOL.length])
    .join('');
}

function password_fields() {
  return document.querySelectorAll<HTMLInputElement>(
    '.password, .password-confirmation'
  );
}

function images() {
  return document.querySelectorAll<HTMLImageElement>('.eye-image');
}

function generatePassword() {
  const value = generate();
  for (const password_field of password_fields()) {
    password_field.value = value;
  }
}

function suggestPassword() {
  generatePassword();
  showPassword();
}

function showPassword() {
  for (const password_field of password_fields()) {
    password_field.type = 'text';
  }
  for (const image of images()) {
    image.src = image.dataset.hidden!;
  }
  visible = true;
}

function hidePassword() {
  for (const password_field of password_fields()) {
    password_field.type = 'password';
  }
  for (const image of images()) {
    image.src = image.dataset.visible!;
  }
  visible = false;
}

function togglePassword() {
  visible ? hidePassword() : showPassword();
}

addEventListener('click', (event: Event) => {
  const target = event.target as HTMLElement;
  if (
    target.matches('.password-visibility-button, .password-visibility-button *')
  ) {
    togglePassword();
  } else if (
    target.matches('.suggest-password-button, .suggest-password-button *')
  ) {
    suggestPassword();
  } else if (target.matches('.hide-password-button, .hide-password-button *')) {
    hidePassword();
  }
});
